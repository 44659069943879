
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Simulation from "@/data/Ht-Tr-technology/Simulation.json";
import Report from "@/data/Ht-Tr-technology/Report.json";

export default defineComponent({
  name: "projects",
  components: {},
  data(): {
    DataType: string;
    ProductType: string;
    DataNumber: string;
    Simulation: any;
    Report: any;
    Step: number;
  } {
    return {
      DataType: "",
      ProductType: "",
      DataNumber: "",
      Simulation: Simulation,
      Report: Report,
      Step: 2,
    };
  },
  methods: {
    nextStep() {
      if (this.Step == 1) {
        this.Step++;
      } else {
        this.Step--;
      }
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("品質模擬報告", ["熱處理技術服務雲系統"]);
    });

    return {};
  },
});
